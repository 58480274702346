import React from "react";

export default function Button({
  title,
  width,
  color = "",
  textColor = "",
  height,
  disabled,
  onButtonClick,
  additionStyle = "",
  ...rest
}) {
  return (
    <button
      {...rest}
      disabled={disabled}
      onClick={!disabled && onButtonClick}
      className={
        disabled
          ? `InActiveButton ${width} ${height} ${additionStyle} cursor-default  ${color} ${textColor} `
          : `activeButton ${width} ${height} ${additionStyle} cursor-pointer   ${color} ${textColor}`
      }
    >
      <span>{title}</span>
    </button>
  );
}

import { gql } from "@apollo/client";

export const LOGIN_MUTATION = gql`
  mutation Login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      token
      user {
        id
        name
        phone
        email
        lastLogin
        role
        accessAllowed
        company {
          id
          dot
          dba
          name
          mailingAddress
          garagingAddress
          mcNumber
          mailingCity
          garagingCity
          accountOwner
          mailingZip
          garagingZip
          garagingCounty
          mailingCounty
          havePrevAuth
          yearsInBusiness
          mailingState
          garagingState
          phone
          eldProvider
          policyChangeData
          notes
        }
      }
    }
  }
`;
export const FLEXPORT_LOGIN_MUTATION = gql`
  mutation flexPortUserlogin($email: String!, $password: String!) {
    flexPortUserlogin(email: $email, password: $password) {
      token
      user {
        id
        name
        phone
        email
        lastLogin
        role
        accessAllowed
        company {
          id
          dot
          dba
          name
          mailingAddress
          garagingAddress
          mcNumber
          mailingCity
          garagingCity
          accountOwner
          mailingZip
          garagingZip
          garagingCounty
          mailingCounty
          havePrevAuth
          yearsInBusiness
          mailingState
          garagingState
          phone
          eldProvider
          policyChangeData
          notes
        }
      }
    }
  }
`;

export const GET_SINGLE_APPLICATION = gql`
  mutation fetchSingleApplication($appId: ID!) {
    fetchSingleApplication(appId: $appId) {
      id
      stage
      activeAuth
      averageViolations
      doTheseApply
      doTheseApplyCw
      preferedCarrier
      priorityStatus
      operationsDescription
      driversAbove23
      cdlExperience
      anySeriousViolations
      currentProgCust
      businessStructure
      driverSummary
      totalValueTrucks
      totalValueTrailers
      grossAnnualTruckRev
      annualTotalMileage
      haulsUnder50Miles
      haulsUnder200Miles
      haulsUnder500Miles
      haulsAbove500Miles
      currentlyInsured
      otherCoverages
      totalAdditionalInsureds
      totalNamedInsureds
      blanketAdditionalNeeded
      blanketWaiverSubrogations
      stateOrFederalFilingsReq
      snapshotPreview
      effectiveDate
      towingLaborStorageLimit
      trailerInterchangeLimit
      autoLiabilityLossCount
      autoLiabilityLossPaid
      physicalDamageLossCount
      physicalDamageLossPaid
      cargoLossCount
      cargoLossPaid
      truckerGenLiabLossCount
      truckerGenLiabLossPaid
      lossesConfirmedVsPending
      createdAt
      updatedAt
      isTest
      isThereRigging
      numberLosses3Years
      currentPage
      numberDrivers
      numberPowerUnits
      lobs
      avgRadOps
      domiciledWithin50Miles
      limitInsPerPowerUnit
      limitInsPerPowerUnitNonOwned
      perOccurenceLimit
      deductible
      spoilageFreezingCoverage
      mtcAdditionalCoverage
      pollutionCleanUp
      ownersGoodExtension
      nonOwnedTrailer
      expirationDate
      disclaimer
      status
      typeOfOps
      typeOfOpsDescriptionOther
      glOrBop
      yearCurrentBusiness
      limitAl
      limitPd
      limitMtc
      limitGl
      limitNtl
      trailerInterchangeDeductible
      deductibleAl
      deductiblePd
      deductibleMtc
      deductibleGl
      deductibleNtl
      numberLossesPast3YearsGA
      continuousCoverage
      DOTObtain60Days
      typeOfTrucker
      ineligibleOperations
      vehicleHaulOilAndGas
      hazardousMaterialPlacard
      isCEODriver
      isCEOInvolvedInOperations
      bodilyInjuryLiabilityLimit
      otherGlOrBop
      priorInsuranceCancelledOrRenewed
      seriousViolationsWithinPast5Years
      ELDVendorName
      LossControlTechnology
      ELDRequiredToRecord
      commercialVehiclesInsuredOwns
      privatePassengerAutosInsured
      insuredVehiclesUsesInBusiness
      federal
      federalCargo
      progressiveState
      progressiveStateCargo
      progressiveOther
      progressiveEligibilityCriteria
      progressivePolicyState
      progressiveELDVendor
      ELDVendorAccess
      customerSpendLastYear
      nonOwnedVehiclesUsedInBusiness
      cargoCoverageForMobileHomes
      progressiveTrailerInterchangeCoverage
      nonOwnedTrailerPhysicalDamage
      MCS90
      isGAKickOut
      isCWKickOut
      cwLosses
      motorTruckCargoLimit
      towingLaborStorageLimitstr
      trailerInterchangeLimitstr
      isProgKickOut
      vehiclesTowingOrHauling
      vehiclesHualSteel
      previousClaimCw
      totalAccidents
      totalNumberViolations
      totalSuspensions
      primaryAndNoncontributoryForTGL
      primaryAndNoncontributoryForAL
      requireFederalFillingForAL
      requireStateFillingForAL
      hauledRefrigeratedGoods
      ownerFname
      ownerLname
      ownerEmail
      ownerDob
      appStack
      appFlow
      insuredWithNICO
      policyNumbers
      effectiveDates
      newVenture
      towTruckRepossessions
      primaryBusiness
      ifNotPrimaryExplain
      Seasonal
      filedForBankruptcy
      whenFilledForBankruptcy
      explainForBankruptcy
      GrossReceiptsLastYear
      EstimateForComingYear
      BusinessForSale
      operateMoreThanOneState
      listStates
      largestCitiesEntered
      haulHazardousMaterialByEPA
      listingForChemicalMaterials
      hireVehicles
      driversCoveredCompensation
      ifYesNameOfCarrier
      minYearsDrivingExpRequired
      vehiclesOwnerDriven
      driversAllowedToTakeVehiclesHome
      maxDrivingHoursDaily
      maxDrivingHoursWeekly
      driverPayBasis
      lessorBeAdded
      nameAddressOfLessorForEachVehicle
      anyLossPayees
      nameAddressOfMortgageeForEachVehicle
      applicantAwareOfClaim
      provideCompleteDetails
      everBeenDeclined
      ifYesDateAndWhy
      additionalCoverageOptions
      intrastateFilingNeeded
      stateAndPermitNumber
      stateListForCargoFilings
      nameAndAddressPermitIssued
      MCS90EndorsementNeeded
      overWeightCommoditiesHauled
      ifFillingRequiredShowStates
      escortVehiclesTowed
      allowOthersToHaulUnderYourAuthority
      allowOthersToHaulHazardousCommodities
      everChangedYourOperatingName
      operateAnyOtherName
      leaseYourAuthority
      appointAgentsOnYourBehalf
      appliedForAuthorityPast3Years
      authorityWithdrawn
      evidenceRequired
      explainForAboveMultipleBooleanYes
      agreementsWithOtherCarriers
      withWhomAgreementHasBeenMade
      partiesNamedAutoMobilesLiability
      nameOfInsuranceCompanyAndLimitOfLiability
      whosePermitOtherPartiesOperate
      holdHarmlessInAgreement
      barterOrHireVehicles
      premiumBeFinanced
      cargoReqForMobileHomes
      cargoReqForBizDocsOrSecs
      fridgeBreakdownCoverage
      progMTCLimit
      compDeductible
      collDeductible
      forHireTruckingOps
      operateAWarehouse
      setUpOrInstallation
      frackingGasProd
      bizOutsideOfForHireOps
      progGLLimit
      vehicleLeaseProvidePrimaryLiability
      progNTLLimit
      progNonOwnedTrailerPDLimit
      progressive2ndPageKickOut
      filingState
      stateFilingsType
      proViewPolicyState
      snapShotProView
      proViewVehicleAnnualMileage
      proViewTermsAndConditions

      indications {
        id
        carrier
        status
        estimatedPremium
      }
      File {
        id
        name
        description
        type
        url
      }

      package {
        effectiveDate
        expirationDate
        totalLimit
        totalPremium
        totalDeductible
        monthlyPayment
        lob
        pfa
        id
        carrier
        ascendProgramId
        ascendProgramUrl
        ascendInvoiceId
        ascendInvoiceUrl
        ascendInvoiceNumber
        ascendInvoiceDueDate
        quote {
          id
          status
          lob
          bindingPremium
          limit
          deductible
          quoteNumber
          invoicedBy
          admitted
          ascendQuoteId
          issuingCarrier
          billingCarrier
          quoteFileLocation
          quoteSigned
          quoteSignedFileLocation
          purchasedFlag
          oppStage
          policy {
            id
            policyNumber
          }
        }
      }

      company {
        accountOwner
      }
    }
  }
`;

export const GET_SINGLE_PACKAGE_INFO_MUTATION = gql`
  mutation fetchSinglePackage($packageId: ID) {
    fetchSinglePackage(packageId: $packageId) {
      policy {
        id
        policyNumber
      }
      payments {
        id
        paymentStatus
        amountPaid
        ascendInvoiceId
        createdAt
      }
      application {
        id
        stage
        activeAuth
        averageViolations
        doTheseApply
        doTheseApplyCw
        preferedCarrier
        priorityStatus
        operationsDescription
        driversAbove23
        cdlExperience
        anySeriousViolations
        currentProgCust
        businessStructure
        driverSummary
        totalValueTrucks
        totalValueTrailers
        grossAnnualTruckRev
        annualTotalMileage
        haulsUnder50Miles
        haulsUnder200Miles
        haulsUnder500Miles
        haulsAbove500Miles
        currentlyInsured
        otherCoverages
        totalAdditionalInsureds
        totalNamedInsureds
        blanketAdditionalNeeded
        blanketWaiverSubrogations
        stateOrFederalFilingsReq
        snapshotPreview
        effectiveDate
        towingLaborStorageLimit
        trailerInterchangeLimit
        autoLiabilityLossCount
        autoLiabilityLossPaid
        physicalDamageLossCount
        physicalDamageLossPaid
        cargoLossCount
        cargoLossPaid
        truckerGenLiabLossCount
        truckerGenLiabLossPaid
        lossesConfirmedVsPending
        createdAt
        updatedAt
        isTest
        isThereRigging
        numberLosses3Years
        currentPage
        numberDrivers
        numberPowerUnits
        lobs
        avgRadOps
        domiciledWithin50Miles
        limitInsPerPowerUnit
        limitInsPerPowerUnitNonOwned
        perOccurenceLimit
        deductible
        spoilageFreezingCoverage
        mtcAdditionalCoverage
        pollutionCleanUp
        ownersGoodExtension
        nonOwnedTrailer
        expirationDate
        disclaimer
        status
        typeOfOps
        typeOfOpsDescriptionOther
        glOrBop
        yearCurrentBusiness
        limitAl
        limitPd
        limitMtc
        limitGl
        limitNtl
        trailerInterchangeDeductible
        deductibleAl
        deductiblePd
        deductibleMtc
        deductibleGl
        deductibleNtl
        numberLossesPast3YearsGA
        continuousCoverage
        DOTObtain60Days
        typeOfTrucker
        ineligibleOperations
        vehicleHaulOilAndGas
        hazardousMaterialPlacard
        isCEODriver
        isCEOInvolvedInOperations
        bodilyInjuryLiabilityLimit
        otherGlOrBop
        priorInsuranceCancelledOrRenewed
        seriousViolationsWithinPast5Years
        ELDVendorName
        LossControlTechnology
        ELDRequiredToRecord
        commercialVehiclesInsuredOwns
        privatePassengerAutosInsured
        insuredVehiclesUsesInBusiness
        federal
        federalCargo
        progressiveState
        progressiveStateCargo
        progressiveOther
        progressiveEligibilityCriteria
        progressivePolicyState
        progressiveELDVendor
        ELDVendorAccess
        customerSpendLastYear
        nonOwnedVehiclesUsedInBusiness
        cargoCoverageForMobileHomes
        progressiveTrailerInterchangeCoverage
        nonOwnedTrailerPhysicalDamage
        MCS90
        isGAKickOut
        isCWKickOut
        cwLosses
        motorTruckCargoLimit
        towingLaborStorageLimitstr
        trailerInterchangeLimitstr
        isProgKickOut
        vehiclesTowingOrHauling
        vehiclesHualSteel
        previousClaimCw
        totalAccidents
        totalNumberViolations
        totalSuspensions
        primaryAndNoncontributoryForTGL
        primaryAndNoncontributoryForAL
        requireFederalFillingForAL
        requireStateFillingForAL
        hauledRefrigeratedGoods
        indications {
          id
          carrier
          status
          estimatedPremium
        }
      }
      quote {
        id
        status
        lob
        bindingPremium
        limit
        deductible
        quoteNumber
        invoicedBy
        admitted
        ascendQuoteId
        issuingCarrier
        billingCarrier
        quoteFileLocation
        quoteSigned
        quoteSignedFileLocation
        purchasedFlag
        oppStage
        application {
          id
          stage
          activeAuth
          averageViolations
          doTheseApply
          doTheseApplyCw
          preferedCarrier
          priorityStatus
          operationsDescription
          driversAbove23
          cdlExperience
          anySeriousViolations
          currentProgCust
          businessStructure
          driverSummary
          totalValueTrucks
          totalValueTrailers
          grossAnnualTruckRev
          annualTotalMileage
          haulsUnder50Miles
          haulsUnder200Miles
          haulsUnder500Miles
          haulsAbove500Miles
          currentlyInsured
          otherCoverages
          totalAdditionalInsureds
          totalNamedInsureds
          blanketAdditionalNeeded
          blanketWaiverSubrogations
          stateOrFederalFilingsReq
          snapshotPreview
          effectiveDate
          towingLaborStorageLimit
          trailerInterchangeLimit
          autoLiabilityLossCount
          autoLiabilityLossPaid
          physicalDamageLossCount
          physicalDamageLossPaid
          cargoLossCount
          cargoLossPaid
          truckerGenLiabLossCount
          truckerGenLiabLossPaid
          lossesConfirmedVsPending
          createdAt
          updatedAt
          isTest
          isThereRigging
          numberLosses3Years
          currentPage
          numberDrivers
          numberPowerUnits
          lobs
          avgRadOps
          domiciledWithin50Miles
          limitInsPerPowerUnit
          limitInsPerPowerUnitNonOwned
          perOccurenceLimit
          deductible
          spoilageFreezingCoverage
          mtcAdditionalCoverage
          pollutionCleanUp
          ownersGoodExtension
          nonOwnedTrailer
          expirationDate
          disclaimer
          status
          typeOfOps
          typeOfOpsDescriptionOther
          glOrBop
          yearCurrentBusiness
          limitAl
          limitPd
          limitMtc
          limitGl
          limitNtl
          trailerInterchangeDeductible
          deductibleAl
          deductiblePd
          deductibleMtc
          deductibleGl
          deductibleNtl
          numberLossesPast3YearsGA
        }
      }
      effectiveDate
      expirationDate
      totalLimit
      totalPremium
      totalDeductible
      monthlyPayment
      lob
      pfa
      id
    }
  }
`;

export const LT_LOGIN_MUTATION = gql`
  mutation ltTeamLogin(
    $email: String!
    $password: String!
    $org: Organizations!
  ) {
    ltTeamLogin(email: $email, password: $password, org: $org) {
      token
      user {
        id
        name
        phone
        email
        roles
        isAdmin
      }
    }
  }
`;

export const GET_PLATFORM_FEE_DATA = gql`
  mutation getPlatformFeeData {
    getPlatformFeeData {
      id
      name
      type
      value
    }
  }
`;

export const UPDATE_PLATFORM_FEE = gql`
  mutation upsertUniverse($id: ID!, $inputData: UniverseInput) {
    upsertUniverse(id: $id, inputData: $inputData) {
      id
      name
      type
      value
    }
  }
`;

export const GET_LT_USER_COMPANIES_LIST = gql`
  mutation companiesList {
    companiesList {
      id
      dot
      name
      phone
      garagingAddress
      garagingCity
      garagingZip
      garagingState
      garagingCounty
      users {
        id
        name
        role
      }
    }
  }
`;

export const SIGNUP_MUTATION = gql`
  mutation Signup(
    $email: String!
    $password: String!
    $name: String!
    $role: Role
    $phone: String
    $accessAllowed: Boolean!
    $org: Organizations!
  ) {
    signUp(
      email: $email
      password: $password
      name: $name
      phone: $phone
      role: $role
      accessAllowed: $accessAllowed
      org: $org
    ) {
      message
    }
  }
`;

export const SIGNUP_USER_WITH_MUTATION = gql`
  mutation singUpUserWithCompany(
    $email: String!
    $name: String!
    $password: String!
    $phone: String!
    $companyId: String!
  ) {
    singUpUserWithCompany(
      email: $email
      name: $name
      password: $password
      phone: $phone
      companyId: $companyId
    ) {
      message
    }
  }
`;
export const DISPATCHER_SIGNUP_MUTATION = gql`
  mutation dispatcherSignUp(
    $email: String!
    $password: String!
    $name: String!
    $phone: String!
  ) {
    dispatcherSignUp(
      email: $email
      password: $password
      name: $name
      phone: $phone
    ) {
      message
    }
  }
`;
export const SOCIAL_AUTHENTICATION_ON_CREATE_COMPANY = gql`
  mutation socialAuthenticationOnCreateCompany($email: String!) {
    socialAuthenticationOnCreateCompany(email: $email) {
      token
      user {
        id
        name
        phone
        email
        lastLogin
        role
        accessAllowed
        company {
          id
          dot
          dba
          name
          mailingAddress
          garagingAddress
          mcNumber
          mailingCity
          garagingCity
          accountOwner
          mailingZip
          garagingZip
          garagingCounty
          mailingCounty
          havePrevAuth
          yearsInBusiness
          mailingState
          garagingState
          phone
          eldProvider
          policyChangeData
          notes
        }
      }
    }
  }
`;
export const LT_CREATE_ADMIN_USER_MUTATION = gql`
  mutation createAdminUserForNewCompany(
    $email: String!
    $name: String!
    $phone: String!
    $role: Role!
    $accessAllowed: Boolean!
  ) {
    createAdminUserForNewCompany(
      email: $email
      name: $name
      phone: $phone
      role: $role
      accessAllowed: $accessAllowed
    ) {
      token
      user {
        id
        email
        role
        accessAllowed
      }
    }
  }
`;

export const REQUEST_COMPANY_ACCESS_FOR_DISPATCHER_MUTATION = gql`
  mutation connectDispatcherWithCompany($companyId: String!, $role: Role!) {
    connectDispatcherWithCompany(companyId: $companyId, role: $role) {
      id
      name
      email
    }
  }
`;

export const REGISTER_USER_AND_COMPANY_MUTATION = gql`
  mutation registerUserAndCompanyByBroker(
    $user: UserInput
    $company: CompanyInput
  ) {
    registerUserAndCompanyByBroker(user: $user, company: $company) {
      user {
        id
        name
        phone
        email
        lastLogin
        role
        accessAllowed
        company {
          id
          dot
          dba
          name
          mailingAddress
          garagingAddress
          mcNumber
          mailingCity
          garagingCity
          accountOwner
          mailingZip
          garagingZip
          garagingCounty
          mailingCounty
          havePrevAuth
          yearsInBusiness
          mailingState
          garagingState
          phone
          eldProvider
          policyChangeData
          notes
        }
      }
      token
    }
  }
`;

export const UPDATE_DISPATCHER_REQUEST_MUTATION = gql`
  mutation updateDispatcherRequestStatus(
    $status: String!
    $dispatcherId: String!
    $role: Role!
    $companyId: String!
  ) {
    updateDispatcherRequestStatus(
      status: $status
      dispatcherId: $dispatcherId
      role: $role
      companyId: $companyId
    ) {
      id
      name
      email
      companies {
        role
        accessAllowed
        company {
          id
          name
        }
      }
    }
  }
`;

export const CREATE_UPDATE_COMPANY_MUTATION = gql`
  mutation UpsertCompany($input: CompanyInput, $companyId: ID) {
    upsertCompany(input: $input, companyId: $companyId) {
      id
      dot
      dba
      name
      mailingAddress
      garagingAddress
      garagingCounty
      mailingCounty
      mcNumber
      mailingCity
      garagingCity
      mailingZip
      garagingZip
      yearsInBusiness
      mailingState
      garagingState
      phone
      havePrevAuth
      policyChangeData
      eldProvider
      notes
    }
  }
`;

export const UPDATE_POLICY_LOBS_MUTATION = gql`
  mutation updatePolicyLOBS($policyId: ID!, $lobs: String!) {
    updatePolicyLOBS(policyId: $policyId, lobs: $lobs) {
      id
      status
      policyNumber
      totalPremium
      endorsmentsPremium
      issuingCarrier
      billingCarrier
      effectiveDate
      expirationDate
      accountManager
      lob
      endorsments {
        id
        name
        status
        Operations {
          id
          action
          subject
          subjectKey
        }
      }
      package {
        id
        totalLimit
        totalDeductible
        totalPremium
        totalAmountPaid
        pfa
        application {
          id
        }
      }
    }
  }
`;

export const REMOVE_POLICY_LOBS_MUTATION = gql`
  mutation removePolicyLobs($policyId: ID!, $lobs: String!) {
    removePolicyLobs(policyId: $policyId, lobs: $lobs) {
      id
      status
      policyNumber
      totalPremium
      endorsmentsPremium
      issuingCarrier
      billingCarrier
      effectiveDate
      expirationDate
      accountManager
      lob
      endorsments {
        id
        name
        status
        Operations {
          id
          action
          subject
          subjectKey
        }
      }
      package {
        id
        totalLimit
        totalDeductible
        totalPremium
        totalAmountPaid
        pfa
        application {
          id
        }
      }
    }
  }
`;

export const ADD_ADDITIONAL_INSURED_MUTATION = gql`
  mutation additionalInsuredEndorsement(
    $policyId: ID!
    $additionalInsured: String!
  ) {
    additionalInsuredEndorsement(
      policyId: $policyId
      additionalInsured: $additionalInsured
    ) {
      id
      status
      policyNumber
      totalPremium
      endorsmentsPremium
      issuingCarrier
      billingCarrier
      effectiveDate
      expirationDate
      accountManager
      lob
      endorsments {
        id
        name
        status
        Operations {
          id
          action
          subject
          subjectKey
        }
      }
      package {
        id
        totalLimit
        totalDeductible
        totalPremium
        totalAmountPaid
        pfa
        application {
          id
        }
      }
    }
  }
`;

export const GET_SINGLE_DRIVER_DETAILS_MUTATION = gql`
  mutation driverDetails($driverId: ID!) {
    driverDetails(driverId: $driverId) {
      id
      company {
        id
      }
      firstName
      lastName
      dob
      dlNumber
      dlState
      dateOfHire
      phone
      dlYearsLicensed
      mvrStatus
      email
      phone
      mvrCheckDate
      address
      city
      state
      zip
      isOwner
      eligibility
      yearsExperience
      isSr22
      sr22CaseNumber
      maritalStatus
      hasAnyViolationsInPast5Years
      past3YearMajorViolations
      past3YearsAccidents
      past3YearsViolations
      totalMajorConvictions
      past3YearsSuspensions
      accidentsViolationsDetails
      totalMajorConvictionsDates
      past5YearsAccidentsViolations
      licenseIssueDate
      MotorVehicleRecord {
        id
        reportId
        reportUri
        mvrId
        createdAt
        updatedAt
        turnaround_time
        license_type
        license_status
        license_state
        license_number
        license_class
        expiration_date
        issued_date
        first_issued_date
        inferred_issued_date
        previous_license_number
        previous_license_state
        experience_failed
        result
        not_found
        accidents
        violations
        custom_rules
        restrictions
        endorsements
        File {
          id
          name
          description
          type
          url
        }
      }
    }
  }
`;

export const CREATE_UPDATE_INDICATION_MUTATION = gql`
  mutation upsertIndication($id: ID!, $appId: ID!, $input: IndicationInput) {
    upsertIndication(id: $id, input: $input, appId: $appId) {
      id
      carrier
      status
      application {
        id
      }
      company {
        id
      }
    }
  }
`;

export const GET_COMPANY_DOCUMENT_FILE_URL_MUTATION = gql`
  mutation getCompanyDocumentsFileUrl($key: String!) {
    getCompanyDocumentsFileUrl(key: $key)
  }
`;
export const INDICATION_SLACK_NOTIFICATION_MUTATION = gql`
  mutation indicationSlackNotification {
    indicationSlackNotification
  }
`;
export const USER_CONTACT_BROKER_SLACK_NOTIFICATION_MUTATION = gql`
  mutation indicationBrokerCallSlackNotification {
    indicationBrokerCallSlackNotification
  }
`;

export const USER_CONTACT_BROKER_FOR_DOWNPAYMENT_SLACK_NOTIFICATION_MUTATION = gql`
  mutation userDownPaymentPurchaseNotification(
    $packageId: ID
    $carrier: String
  ) {
    userDownPaymentPurchaseNotification(
      packageId: $packageId
      carrier: $carrier
    )
  }
`;

export const UPDATE_QUOTE_PURCHASE_FLAG_MUTATION = gql`
  mutation updateQuotePurchaseFlag($packageId: ID, $carrier: String) {
    updateQuotePurchaseFlag(packageId: $packageId, carrier: $carrier) {
      id
      stage
      activeAuth
      averageViolations
      doTheseApply
      doTheseApplyCw
      preferedCarrier
      priorityStatus
      operationsDescription
      driversAbove23
      cdlExperience
      anySeriousViolations
      currentProgCust
      businessStructure
      driverSummary
      totalValueTrucks
      totalValueTrailers
      grossAnnualTruckRev
      annualTotalMileage
      haulsUnder50Miles
      haulsUnder200Miles
      haulsUnder500Miles
      haulsAbove500Miles
      currentlyInsured
      otherCoverages
      totalAdditionalInsureds
      totalNamedInsureds
      blanketAdditionalNeeded
      blanketWaiverSubrogations
      stateOrFederalFilingsReq
      snapshotPreview
      effectiveDate
      towingLaborStorageLimit
      trailerInterchangeLimit
      autoLiabilityLossCount
      autoLiabilityLossPaid
      physicalDamageLossCount
      physicalDamageLossPaid
      cargoLossCount
      cargoLossPaid
      truckerGenLiabLossCount
      truckerGenLiabLossPaid
      lossesConfirmedVsPending
      createdAt
      updatedAt
      isTest
      isThereRigging
      numberLosses3Years
      currentPage
      numberDrivers
      numberPowerUnits
      lobs
      avgRadOps
      domiciledWithin50Miles
      limitInsPerPowerUnit
      limitInsPerPowerUnitNonOwned
      perOccurenceLimit
      deductible
      spoilageFreezingCoverage
      mtcAdditionalCoverage
      pollutionCleanUp
      ownersGoodExtension
      nonOwnedTrailer
      expirationDate
      disclaimer
      status
      typeOfOps
      typeOfOpsDescriptionOther
      glOrBop
      yearCurrentBusiness
      limitAl
      limitPd
      limitMtc
      limitGl
      limitNtl
      trailerInterchangeDeductible
      deductibleAl
      deductiblePd
      deductibleMtc
      deductibleGl
      deductibleNtl
      numberLossesPast3YearsGA
      continuousCoverage
      DOTObtain60Days
      typeOfTrucker
      ineligibleOperations
      vehicleHaulOilAndGas
      hazardousMaterialPlacard
      isCEODriver
      isCEOInvolvedInOperations
      bodilyInjuryLiabilityLimit
      otherGlOrBop
      priorInsuranceCancelledOrRenewed
      seriousViolationsWithinPast5Years
      ELDVendorName
      LossControlTechnology
      ELDRequiredToRecord
      commercialVehiclesInsuredOwns
      privatePassengerAutosInsured
      insuredVehiclesUsesInBusiness
      federal
      federalCargo
      progressiveState
      progressiveStateCargo
      progressiveOther
      progressiveEligibilityCriteria
      progressivePolicyState
      progressiveELDVendor
      ELDVendorAccess
      customerSpendLastYear
      nonOwnedVehiclesUsedInBusiness
      cargoCoverageForMobileHomes
      progressiveTrailerInterchangeCoverage
      nonOwnedTrailerPhysicalDamage
      MCS90
      isGAKickOut
      isCWKickOut
      cwLosses
      motorTruckCargoLimit
      towingLaborStorageLimitstr
      trailerInterchangeLimitstr
      isProgKickOut
      vehiclesTowingOrHauling
      vehiclesHualSteel
      previousClaimCw
      totalAccidents
      totalNumberViolations
      totalSuspensions
      primaryAndNoncontributoryForTGL
      primaryAndNoncontributoryForAL
      requireFederalFillingForAL
      requireStateFillingForAL
      hauledRefrigeratedGoods
      ownerFname
      ownerLname
      ownerEmail
      ownerDob
      appStack
      appFlow
      insuredWithNICO
      policyNumbers
      effectiveDates
      newVenture
      towTruckRepossessions
      primaryBusiness
      ifNotPrimaryExplain
      Seasonal
      filedForBankruptcy
      whenFilledForBankruptcy
      explainForBankruptcy
      GrossReceiptsLastYear
      EstimateForComingYear
      BusinessForSale
      operateMoreThanOneState
      listStates
      largestCitiesEntered
      haulHazardousMaterialByEPA
      listingForChemicalMaterials
      hireVehicles
      driversCoveredCompensation
      ifYesNameOfCarrier
      minYearsDrivingExpRequired
      vehiclesOwnerDriven
      driversAllowedToTakeVehiclesHome
      maxDrivingHoursDaily
      maxDrivingHoursWeekly
      driverPayBasis
      lessorBeAdded
      nameAddressOfLessorForEachVehicle
      anyLossPayees
      nameAddressOfMortgageeForEachVehicle
      applicantAwareOfClaim
      provideCompleteDetails
      everBeenDeclined
      ifYesDateAndWhy
      additionalCoverageOptions
      intrastateFilingNeeded
      stateAndPermitNumber
      stateListForCargoFilings
      nameAndAddressPermitIssued
      MCS90EndorsementNeeded
      overWeightCommoditiesHauled
      ifFillingRequiredShowStates
      escortVehiclesTowed
      allowOthersToHaulUnderYourAuthority
      allowOthersToHaulHazardousCommodities
      everChangedYourOperatingName
      operateAnyOtherName
      leaseYourAuthority
      appointAgentsOnYourBehalf
      appliedForAuthorityPast3Years
      authorityWithdrawn
      evidenceRequired
      explainForAboveMultipleBooleanYes
      agreementsWithOtherCarriers
      withWhomAgreementHasBeenMade
      partiesNamedAutoMobilesLiability
      nameOfInsuranceCompanyAndLimitOfLiability
      whosePermitOtherPartiesOperate
      holdHarmlessInAgreement
      barterOrHireVehicles
      premiumBeFinanced
      cargoReqForMobileHomes
      cargoReqForBizDocsOrSecs
      fridgeBreakdownCoverage
      progMTCLimit
      compDeductible
      collDeductible
      forHireTruckingOps
      operateAWarehouse
      setUpOrInstallation
      frackingGasProd
      bizOutsideOfForHireOps
      progGLLimit
      vehicleLeaseProvidePrimaryLiability
      progNTLLimit
      progNonOwnedTrailerPDLimit
      progressive2ndPageKickOut
      filingState
      stateFilingsType
      proViewPolicyState
      snapShotProView
      proViewVehicleAnnualMileage
      proViewTermsAndConditions
      indications {
        id
        carrier
        status
        estimatedPremium
      }
      File {
        id
        name
        description
        type
        url
      }

      package {
        effectiveDate
        expirationDate
        totalLimit
        totalPremium
        totalDeductible
        monthlyPayment
        lob
        pfa
        id
        carrier
        ascendProgramId
        ascendProgramUrl
        ascendInvoiceId
        ascendInvoiceUrl
        ascendInvoiceNumber
        ascendInvoiceDueDate
        File {
          id
          name
          description
          type
          url
        }
        quote {
          id
          status
          lob
          bindingPremium
          limit
          deductible
          quoteNumber
          invoicedBy
          admitted
          ascendQuoteId
          issuingCarrier
          billingCarrier
          quoteFileLocation
          quoteSigned
          quoteSignedFileLocation
          purchasedFlag
          oppStage
          taxes
          platformFee
          PlatformFeeType
          policy {
            id
            policyNumber
          }
          File {
            id
            name
            description
            type
            url
          }
        }
      }
      company {
        accountOwner
      }
    }
  }
`;

export const UPDATE_NOTIFICATION_STATUS_MUTATION = gql`
  mutation updateNotificationStatus($id: String!) {
    updateNotificationStatus(id: $id) {
      id
      type
      status
      createdAt
      lastUpdated
      payload
      severity
      seenBy
    }
  }
`;

export const GET_INSTANT_QUOTE_GA = gql`
  mutation instantQuoteGA(
    $applicationId: ID!
    $yearsInBusiness: Int
    $numberPowerUnits: Int
    $numberLosses3Years: Int
    $commoditiesHauled: Boolean
    $averageViolations: Float
    $requestedBy: Organizations
  ) {
    instantQuoteGA(
      yearsInBusiness: $yearsInBusiness
      applicationId: $applicationId
      numberPowerUnits: $numberPowerUnits
      numberLosses3Years: $numberLosses3Years
      commoditiesHauled: $commoditiesHauled
      averageViolations: $averageViolations
      requestedBy: $requestedBy
    ) {
      policyExpiryDate
      premium
      policyEffectiveDate
      minPremium
      quoteNumber
      carrier
    }
  }
`;

export const DELETE_APPLICATION_MUTATION = gql`
  mutation deleteApp($applicationId: ID!) {
    deleteApp(applicationId: $applicationId) {
      id
    }
  }
`;
export const APP_FLOW_MUTATION = gql`
  mutation appFlowHandler($applicationId: ID!, $input: ApplicationInput) {
    appFlowHandler(applicationId: $applicationId, input: $input) {
      company {
        id
      }
      id
      stage
      activeAuth
      averageViolations
      doTheseApply
      doTheseApplyCw
      preferedCarrier
      priorityStatus
      operationsDescription
      driversAbove23
      cdlExperience
      anySeriousViolations
      currentProgCust
      businessStructure
      driverSummary
      totalValueTrucks
      totalValueTrailers
      grossAnnualTruckRev
      annualTotalMileage
      haulsUnder50Miles
      haulsUnder200Miles
      haulsUnder500Miles
      haulsAbove500Miles
      currentlyInsured
      otherCoverages
      totalAdditionalInsureds
      totalNamedInsureds
      blanketAdditionalNeeded
      blanketWaiverSubrogations
      stateOrFederalFilingsReq
      snapshotPreview
      effectiveDate
      towingLaborStorageLimit
      trailerInterchangeLimit
      autoLiabilityLossCount
      autoLiabilityLossPaid
      physicalDamageLossCount
      physicalDamageLossPaid
      cargoLossCount
      cargoLossPaid
      truckerGenLiabLossCount
      truckerGenLiabLossPaid
      lossesConfirmedVsPending
      createdAt
      updatedAt
      isTest
      isThereRigging
      numberLosses3Years
      currentPage
      numberDrivers
      numberPowerUnits
      lobs
      avgRadOps
      domiciledWithin50Miles
      limitInsPerPowerUnit
      limitInsPerPowerUnitNonOwned
      perOccurenceLimit
      deductible
      spoilageFreezingCoverage
      mtcAdditionalCoverage
      pollutionCleanUp
      ownersGoodExtension
      nonOwnedTrailer
      expirationDate
      disclaimer
      status
      typeOfOps
      typeOfOpsDescriptionOther
      glOrBop
      yearCurrentBusiness
      limitAl
      limitPd
      limitMtc
      limitGl
      limitNtl
      trailerInterchangeDeductible
      deductibleAl
      deductiblePd
      deductibleMtc
      deductibleGl
      deductibleNtl
      numberLossesPast3YearsGA
      continuousCoverage
      DOTObtain60Days
      typeOfTrucker
      ineligibleOperations
      vehicleHaulOilAndGas
      hazardousMaterialPlacard
      isCEODriver
      isCEOInvolvedInOperations
      bodilyInjuryLiabilityLimit
      otherGlOrBop
      priorInsuranceCancelledOrRenewed
      seriousViolationsWithinPast5Years
      ELDVendorName
      LossControlTechnology
      ELDRequiredToRecord
      commercialVehiclesInsuredOwns
      privatePassengerAutosInsured
      insuredVehiclesUsesInBusiness
      federal
      federalCargo
      progressiveState
      progressiveStateCargo
      progressiveOther
      progressiveEligibilityCriteria
      progressivePolicyState
      progressiveELDVendor
      ELDVendorAccess
      customerSpendLastYear
      nonOwnedVehiclesUsedInBusiness
      cargoCoverageForMobileHomes
      progressiveTrailerInterchangeCoverage
      nonOwnedTrailerPhysicalDamage
      MCS90
      isGAKickOut
      isCWKickOut
      cwLosses
      motorTruckCargoLimit
      towingLaborStorageLimitstr
      trailerInterchangeLimitstr
      isProgKickOut
      vehiclesTowingOrHauling
      vehiclesHualSteel
      previousClaimCw
      totalAccidents
      totalNumberViolations
      totalSuspensions
      primaryAndNoncontributoryForTGL
      primaryAndNoncontributoryForAL
      requireFederalFillingForAL
      requireStateFillingForAL
      hauledRefrigeratedGoods
      ownerFname
      ownerLname
      ownerEmail
      ownerDob
      appStack
      appFlow
      insuredWithNICO
      policyNumbers
      effectiveDates
      newVenture
      towTruckRepossessions
      primaryBusiness
      ifNotPrimaryExplain
      Seasonal
      filedForBankruptcy
      whenFilledForBankruptcy
      explainForBankruptcy
      GrossReceiptsLastYear
      EstimateForComingYear
      BusinessForSale
      operateMoreThanOneState
      listStates
      largestCitiesEntered
      haulHazardousMaterialByEPA
      listingForChemicalMaterials
      hireVehicles
      driversCoveredCompensation
      ifYesNameOfCarrier
      minYearsDrivingExpRequired
      vehiclesOwnerDriven
      driversAllowedToTakeVehiclesHome
      maxDrivingHoursDaily
      maxDrivingHoursWeekly
      driverPayBasis
      lessorBeAdded
      nameAddressOfLessorForEachVehicle
      anyLossPayees
      nameAddressOfMortgageeForEachVehicle
      applicantAwareOfClaim
      provideCompleteDetails
      everBeenDeclined
      ifYesDateAndWhy
      additionalCoverageOptions
      intrastateFilingNeeded
      stateAndPermitNumber
      stateListForCargoFilings
      nameAndAddressPermitIssued
      MCS90EndorsementNeeded
      overWeightCommoditiesHauled
      ifFillingRequiredShowStates
      escortVehiclesTowed
      allowOthersToHaulUnderYourAuthority
      allowOthersToHaulHazardousCommodities
      everChangedYourOperatingName
      operateAnyOtherName
      leaseYourAuthority
      appointAgentsOnYourBehalf
      appliedForAuthorityPast3Years
      authorityWithdrawn
      evidenceRequired
      explainForAboveMultipleBooleanYes
      agreementsWithOtherCarriers
      withWhomAgreementHasBeenMade
      partiesNamedAutoMobilesLiability
      nameOfInsuranceCompanyAndLimitOfLiability
      whosePermitOtherPartiesOperate
      holdHarmlessInAgreement
      barterOrHireVehicles
      premiumBeFinanced
      cargoReqForMobileHomes
      cargoReqForBizDocsOrSecs
      fridgeBreakdownCoverage
      progMTCLimit
      compDeductible
      collDeductible
      forHireTruckingOps
      operateAWarehouse
      setUpOrInstallation
      frackingGasProd
      bizOutsideOfForHireOps
      progGLLimit
      vehicleLeaseProvidePrimaryLiability
      progNTLLimit
      progNonOwnedTrailerPDLimit
      progressive2ndPageKickOut
      filingState
      stateFilingsType
      proViewPolicyState
      snapShotProView
      proViewVehicleAnnualMileage
      proViewTermsAndConditions
      typeOfOpsCW
      monthInBusiness
      edited
      progCurrentPolicyexpirationDate
      coverWhaleOptAlUm
      coverWhaleOptAlPip
      authorityNumber
      indications {
        id
        carrier
        status
        estimatedPremium
      }
      File {
        id
        name
        description
        type
        url
      }
    }
  }
`;

export const EDIT_APPLICATION_MUTATION = gql`
  mutation editApplication($applicationId: ID!) {
    editApplication(applicationId: $applicationId) {
      id
      stage
      activeAuth
      averageViolations
      doTheseApply
      doTheseApplyCw
      preferedCarrier
      priorityStatus
      operationsDescription
      driversAbove23
      cdlExperience
      anySeriousViolations
      currentProgCust
      businessStructure
      driverSummary
      totalValueTrucks
      totalValueTrailers
      grossAnnualTruckRev
      annualTotalMileage
      haulsUnder50Miles
      haulsUnder200Miles
      haulsUnder500Miles
      haulsAbove500Miles
      currentlyInsured
      otherCoverages
      totalAdditionalInsureds
      totalNamedInsureds
      blanketAdditionalNeeded
      blanketWaiverSubrogations
      stateOrFederalFilingsReq
      snapshotPreview
      effectiveDate
      towingLaborStorageLimit
      trailerInterchangeLimit
      autoLiabilityLossCount
      autoLiabilityLossPaid
      physicalDamageLossCount
      physicalDamageLossPaid
      cargoLossCount
      cargoLossPaid
      truckerGenLiabLossCount
      truckerGenLiabLossPaid
      lossesConfirmedVsPending
      createdAt
      updatedAt
      isTest
      isThereRigging
      numberLosses3Years
      currentPage
      numberDrivers
      numberPowerUnits
      lobs
      avgRadOps
      domiciledWithin50Miles
      limitInsPerPowerUnit
      limitInsPerPowerUnitNonOwned
      perOccurenceLimit
      deductible
      spoilageFreezingCoverage
      mtcAdditionalCoverage
      pollutionCleanUp
      ownersGoodExtension
      nonOwnedTrailer
      expirationDate
      disclaimer
      status
      typeOfOps
      typeOfOpsDescriptionOther
      glOrBop
      yearCurrentBusiness
      limitAl
      limitPd
      limitMtc
      limitGl
      limitNtl
      trailerInterchangeDeductible
      deductibleAl
      deductiblePd
      deductibleMtc
      deductibleGl
      deductibleNtl
      numberLossesPast3YearsGA
      continuousCoverage
      DOTObtain60Days
      typeOfTrucker
      ineligibleOperations
      vehicleHaulOilAndGas
      hazardousMaterialPlacard
      isCEODriver
      isCEOInvolvedInOperations
      bodilyInjuryLiabilityLimit
      otherGlOrBop
      priorInsuranceCancelledOrRenewed
      seriousViolationsWithinPast5Years
      ELDVendorName
      LossControlTechnology
      ELDRequiredToRecord
      commercialVehiclesInsuredOwns
      privatePassengerAutosInsured
      insuredVehiclesUsesInBusiness
      federal
      federalCargo
      progressiveState
      progressiveStateCargo
      progressiveOther
      progressiveEligibilityCriteria
      progressivePolicyState
      progressiveELDVendor
      ELDVendorAccess
      customerSpendLastYear
      nonOwnedVehiclesUsedInBusiness
      cargoCoverageForMobileHomes
      progressiveTrailerInterchangeCoverage
      nonOwnedTrailerPhysicalDamage
      MCS90
      isGAKickOut
      isCWKickOut
      cwLosses
      motorTruckCargoLimit
      towingLaborStorageLimitstr
      trailerInterchangeLimitstr
      isProgKickOut
      vehiclesTowingOrHauling
      vehiclesHualSteel
      previousClaimCw
      totalAccidents
      totalNumberViolations
      totalSuspensions
      primaryAndNoncontributoryForTGL
      primaryAndNoncontributoryForAL
      requireFederalFillingForAL
      requireStateFillingForAL
      hauledRefrigeratedGoods
      ownerFname
      ownerLname
      ownerEmail
      ownerDob
      appStack
      appFlow
      insuredWithNICO
      policyNumbers
      effectiveDates
      newVenture
      towTruckRepossessions
      primaryBusiness
      ifNotPrimaryExplain
      Seasonal
      filedForBankruptcy
      whenFilledForBankruptcy
      explainForBankruptcy
      GrossReceiptsLastYear
      EstimateForComingYear
      BusinessForSale
      operateMoreThanOneState
      listStates
      largestCitiesEntered
      haulHazardousMaterialByEPA
      listingForChemicalMaterials
      hireVehicles
      driversCoveredCompensation
      ifYesNameOfCarrier
      minYearsDrivingExpRequired
      vehiclesOwnerDriven
      driversAllowedToTakeVehiclesHome
      maxDrivingHoursDaily
      maxDrivingHoursWeekly
      driverPayBasis
      lessorBeAdded
      nameAddressOfLessorForEachVehicle
      anyLossPayees
      nameAddressOfMortgageeForEachVehicle
      applicantAwareOfClaim
      provideCompleteDetails
      everBeenDeclined
      ifYesDateAndWhy
      additionalCoverageOptions
      intrastateFilingNeeded
      stateAndPermitNumber
      stateListForCargoFilings
      nameAndAddressPermitIssued
      MCS90EndorsementNeeded
      overWeightCommoditiesHauled
      ifFillingRequiredShowStates
      escortVehiclesTowed
      allowOthersToHaulUnderYourAuthority
      allowOthersToHaulHazardousCommodities
      everChangedYourOperatingName
      operateAnyOtherName
      leaseYourAuthority
      appointAgentsOnYourBehalf
      appliedForAuthorityPast3Years
      authorityWithdrawn
      evidenceRequired
      explainForAboveMultipleBooleanYes
      agreementsWithOtherCarriers
      withWhomAgreementHasBeenMade
      partiesNamedAutoMobilesLiability
      nameOfInsuranceCompanyAndLimitOfLiability
      whosePermitOtherPartiesOperate
      holdHarmlessInAgreement
      barterOrHireVehicles
      premiumBeFinanced
      cargoReqForMobileHomes
      cargoReqForBizDocsOrSecs
      fridgeBreakdownCoverage
      progMTCLimit
      compDeductible
      collDeductible
      forHireTruckingOps
      operateAWarehouse
      setUpOrInstallation
      frackingGasProd
      bizOutsideOfForHireOps
      progGLLimit
      vehicleLeaseProvidePrimaryLiability
      progNTLLimit
      progNonOwnedTrailerPDLimit
      progressive2ndPageKickOut
      filingState
      stateFilingsType
      proViewPolicyState
      snapShotProView
      proViewVehicleAnnualMileage
      proViewTermsAndConditions
      monthInBusiness
      typeOfOpsCW
      edited
      progCurrentPolicyexpirationDate

      indications {
        id
        carrier
        status
        estimatedPremium
      }
      File {
        id
        name
        description
        type
        url
      }

      package {
        effectiveDate
        expirationDate
        totalLimit
        totalPremium
        totalDeductible
        monthlyPayment
        downPaymentPercentage
        lob
        pfa
        id
        carrier
        ascendProgramId
        ascendProgramUrl
        ascendInvoiceId
        ascendInvoiceUrl
        ascendInvoiceNumber
        ascendInvoiceDueDate
        File {
          id
          name
          type
          url
        }
        quote {
          File {
            id
            name
            type
            url
          }
          id
          status
          lob
          bindingPremium
          limit
          deductible
          quoteNumber
          invoicedBy
          admitted
          ascendQuoteId
          issuingCarrier
          billingCarrier
          quoteFileLocation
          quoteSigned
          quoteSignedFileLocation
          purchasedFlag
          oppStage
          downPaymentPercentage
          downPaymentAmount
          paymentType
          paymentSource
          applicationError
          declinedReason
          taxes
          platformFee
          PlatformFeeType
          policy {
            id
            policyNumber
          }
        }
      }

      company {
        accountOwner
      }
    }
  }
`;

export const EDIT_CW_USER_FLOW_APPLICATION_MUTATION = gql`
  mutation editCWUserFlowApplication($applicationId: ID!) {
    editCWUserFlowApplication(applicationId: $applicationId) {
      id
      stage
      activeAuth
      averageViolations
      doTheseApply
      doTheseApplyCw
      preferedCarrier
      priorityStatus
      operationsDescription
      driversAbove23
      cdlExperience
      anySeriousViolations
      currentProgCust
      businessStructure
      driverSummary
      totalValueTrucks
      totalValueTrailers
      grossAnnualTruckRev
      annualTotalMileage
      haulsUnder50Miles
      haulsUnder200Miles
      haulsUnder500Miles
      haulsAbove500Miles
      currentlyInsured
      otherCoverages
      totalAdditionalInsureds
      totalNamedInsureds
      blanketAdditionalNeeded
      blanketWaiverSubrogations
      stateOrFederalFilingsReq
      snapshotPreview
      effectiveDate
      towingLaborStorageLimit
      trailerInterchangeLimit
      autoLiabilityLossCount
      autoLiabilityLossPaid
      physicalDamageLossCount
      physicalDamageLossPaid
      cargoLossCount
      cargoLossPaid
      truckerGenLiabLossCount
      truckerGenLiabLossPaid
      lossesConfirmedVsPending
      createdAt
      updatedAt
      isTest
      isThereRigging
      numberLosses3Years
      currentPage
      numberDrivers
      numberPowerUnits
      lobs
      avgRadOps
      domiciledWithin50Miles
      limitInsPerPowerUnit
      limitInsPerPowerUnitNonOwned
      perOccurenceLimit
      deductible
      spoilageFreezingCoverage
      mtcAdditionalCoverage
      pollutionCleanUp
      ownersGoodExtension
      nonOwnedTrailer
      expirationDate
      disclaimer
      status
      typeOfOps
      typeOfOpsDescriptionOther
      glOrBop
      yearCurrentBusiness
      limitAl
      limitPd
      limitMtc
      limitGl
      limitNtl
      trailerInterchangeDeductible
      deductibleAl
      deductiblePd
      deductibleMtc
      deductibleGl
      deductibleNtl
      numberLossesPast3YearsGA
      continuousCoverage
      DOTObtain60Days
      typeOfTrucker
      ineligibleOperations
      vehicleHaulOilAndGas
      hazardousMaterialPlacard
      isCEODriver
      isCEOInvolvedInOperations
      bodilyInjuryLiabilityLimit
      otherGlOrBop
      priorInsuranceCancelledOrRenewed
      seriousViolationsWithinPast5Years
      ELDVendorName
      LossControlTechnology
      ELDRequiredToRecord
      commercialVehiclesInsuredOwns
      privatePassengerAutosInsured
      insuredVehiclesUsesInBusiness
      federal
      federalCargo
      progressiveState
      progressiveStateCargo
      progressiveOther
      progressiveEligibilityCriteria
      progressivePolicyState
      progressiveELDVendor
      ELDVendorAccess
      customerSpendLastYear
      nonOwnedVehiclesUsedInBusiness
      cargoCoverageForMobileHomes
      progressiveTrailerInterchangeCoverage
      nonOwnedTrailerPhysicalDamage
      MCS90
      isGAKickOut
      isCWKickOut
      cwLosses
      motorTruckCargoLimit
      towingLaborStorageLimitstr
      trailerInterchangeLimitstr
      isProgKickOut
      vehiclesTowingOrHauling
      vehiclesHualSteel
      previousClaimCw
      totalAccidents
      totalNumberViolations
      totalSuspensions
      primaryAndNoncontributoryForTGL
      primaryAndNoncontributoryForAL
      requireFederalFillingForAL
      requireStateFillingForAL
      hauledRefrigeratedGoods
      ownerFname
      ownerLname
      ownerEmail
      ownerDob
      appStack
      appFlow
      insuredWithNICO
      policyNumbers
      effectiveDates
      newVenture
      towTruckRepossessions
      primaryBusiness
      ifNotPrimaryExplain
      Seasonal
      filedForBankruptcy
      whenFilledForBankruptcy
      explainForBankruptcy
      GrossReceiptsLastYear
      EstimateForComingYear
      BusinessForSale
      operateMoreThanOneState
      listStates
      largestCitiesEntered
      haulHazardousMaterialByEPA
      listingForChemicalMaterials
      hireVehicles
      driversCoveredCompensation
      ifYesNameOfCarrier
      minYearsDrivingExpRequired
      vehiclesOwnerDriven
      driversAllowedToTakeVehiclesHome
      maxDrivingHoursDaily
      maxDrivingHoursWeekly
      driverPayBasis
      lessorBeAdded
      nameAddressOfLessorForEachVehicle
      anyLossPayees
      nameAddressOfMortgageeForEachVehicle
      applicantAwareOfClaim
      provideCompleteDetails
      everBeenDeclined
      ifYesDateAndWhy
      additionalCoverageOptions
      intrastateFilingNeeded
      stateAndPermitNumber
      stateListForCargoFilings
      nameAndAddressPermitIssued
      MCS90EndorsementNeeded
      overWeightCommoditiesHauled
      ifFillingRequiredShowStates
      escortVehiclesTowed
      allowOthersToHaulUnderYourAuthority
      allowOthersToHaulHazardousCommodities
      everChangedYourOperatingName
      operateAnyOtherName
      leaseYourAuthority
      appointAgentsOnYourBehalf
      appliedForAuthorityPast3Years
      authorityWithdrawn
      evidenceRequired
      explainForAboveMultipleBooleanYes
      agreementsWithOtherCarriers
      withWhomAgreementHasBeenMade
      partiesNamedAutoMobilesLiability
      nameOfInsuranceCompanyAndLimitOfLiability
      whosePermitOtherPartiesOperate
      holdHarmlessInAgreement
      barterOrHireVehicles
      premiumBeFinanced
      cargoReqForMobileHomes
      cargoReqForBizDocsOrSecs
      fridgeBreakdownCoverage
      progMTCLimit
      compDeductible
      collDeductible
      forHireTruckingOps
      operateAWarehouse
      setUpOrInstallation
      frackingGasProd
      bizOutsideOfForHireOps
      progGLLimit
      vehicleLeaseProvidePrimaryLiability
      progNTLLimit
      progNonOwnedTrailerPDLimit
      progressive2ndPageKickOut
      filingState
      stateFilingsType
      proViewPolicyState
      snapShotProView
      proViewVehicleAnnualMileage
      proViewTermsAndConditions
      monthInBusiness
      typeOfOpsCW
      edited
      progCurrentPolicyexpirationDate

      indications {
        id
        carrier
        status
        estimatedPremium
      }
      File {
        id
        name
        description
        type
        url
      }

      package {
        effectiveDate
        expirationDate
        totalLimit
        totalPremium
        totalDeductible
        monthlyPayment
        downPaymentPercentage
        lob
        pfa
        id
        carrier
        ascendProgramId
        ascendProgramUrl
        ascendInvoiceId
        ascendInvoiceUrl
        ascendInvoiceNumber
        ascendInvoiceDueDate
        File {
          id
          name
          type
          url
        }
        quote {
          File {
            id
            name
            type
            url
          }
          id
          status
          lob
          bindingPremium
          limit
          deductible
          quoteNumber
          invoicedBy
          admitted
          ascendQuoteId
          issuingCarrier
          billingCarrier
          quoteFileLocation
          quoteSigned
          quoteSignedFileLocation
          purchasedFlag
          oppStage
          downPaymentPercentage
          downPaymentAmount
          paymentType
          paymentSource
          applicationError
          declinedReason
          taxes
          platformFee
          PlatformFeeType
          policy {
            id
            policyNumber
          }
        }
      }

      company {
        accountOwner
      }
    }
  }
`;

export const MOCK_CW_RATER_MUTATION = gql`
  mutation mockCWRaterMutation($cwRaterInput: CWRaterInput) {
    mockCWRaterMutation(cwRaterInput: $cwRaterInput)
  }
`;

export const CREATE_UPDATE_DRIVER_MUTATION = gql`
  mutation upsertDriver(
    $applicationId: ID!
    $companyId: ID!
    $input: DriverInput
    $driverId: ID!
  ) {
    upsertDriver(
      applicationId: $applicationId
      companyId: $companyId
      input: $input
      driverId: $driverId
    ) {
      id
      firstName
      lastName
      dlNumber
      mvrStatus
    }
  }
`;

export const CREATE_UPDATE_VEHICLE_MUTATION = gql`
  mutation upsertVehicle(
    $applicationId: ID!
    $companyId: ID!
    $input: VehicleInput
    $vehicleId: ID!
  ) {
    upsertVehicle(
      applicationId: $applicationId
      companyId: $companyId
      input: $input
      vehicleId: $vehicleId
    ) {
      id
      modelYear
      vin
    }
  }
`;

export const DRIVER_CREATE_UPDATE_MUTATION = gql`
  mutation upsertDriver($driverId: ID!, $companyId: ID!, $input: DriverInput) {
    upsertDriver(driverId: $driverId, companyId: $companyId, input: $input) {
      id
      firstName
      lastName
      dob
      dlNumber
      dlState
      dateOfHire
      phone
      dlYearsLicensed
      mvrStatus
      email
      phone
      mvrCheckDate
      address
      city
      cdl
      state
      zip
      isOwner
      eligibility
      yearsExperience
      isSr22
      sr22CaseNumber
      maritalStatus
      hasAnyViolationsInPast5Years
      past3YearMajorViolations
      past3YearsAccidents
      past3YearsViolations
      past3YearsSuspensions
      accidentsViolationsDetails
      totalMajorConvictionsDates
      totalMajorConvictions
      past5YearsAccidentsViolations
      licenseIssueDate
      endorsment {
        id
      }
    }
  }
`;

export const COMMODITY_CREATE_UPDATE_MUTATION = gql`
  mutation upsertCommodity(
    $commodityId: ID!
    $companyId: ID!
    $input: CommodityInput
  ) {
    upsertCommodity(
      commodityId: $commodityId
      companyId: $companyId
      input: $input
    ) {
      id
      type
      subType
      minValue
      maxValue
      percentage
      status
      isDeleted
      company {
        name
        id
      }
      endorsment {
        id
      }
    }
  }
`;

export const VEHICLE_CREATE_UPDATE_MUTATION = gql`
  mutation upsertVehicle(
    $applicationId: ID
    $companyId: ID!
    $input: VehicleInput
    $vehicleId: ID!
  ) {
    upsertVehicle(
      applicationId: $applicationId
      companyId: $companyId
      input: $input
      vehicleId: $vehicleId
    ) {
      vin
      id
      vehicleType
      subTrailerType
      subTruckType
      modelYear
      vehicleMake
      vehicleModel
      gvw
      rearAxles
      garagingCity
      operationRadius
      annualMileage
      safetyFeatures
      limit
      createdAt
      ownership
      bodyStyle
      value
      valuePermEquipment
      rearAxles
      vehicleClass
      radius
      vehicleUse
      needCoverage
      vehicleZip
      loanOrLease
      antiTheft
      antiLockBrakes
      airbag
      isDeleted
      valuePermEquipment
      typeOfTrailerHitch
      grossVehicleWeight
      vehicleTonnage
      numberOfDrivingWheels
      percentageOfRepossessionWork
      numberOfRearAxles
      numberOfPassengerSeats
      vehicleEquippedWheelchair
      stretchLength
      typeOfSUV
      vehicleTankSize
      haulGoodsOnForHireBasis
      glassVinEtching
      transportPassengersRevenue
      perimeterSeatingOrPartyBus
      numberOfStalls
      publicTransportationIndicator
      permanentFoundation
      lengthOfTrailer
      collision
      comprehensive
      cwDeductibles
      inPossessionForMoreThan30Days
      isDeleted
      status
      company {
        name
        id
      }
      endorsment {
        id
      }
    }
  }
`;

export const GENERATE_OTP_MUTATION = gql`
  mutation generateOtp($to: String!, $channel: String!) {
    generateOtp(to: $to, channel: $channel) {
      message
    }
  }
`;
export const UPDATE_USER_ROLE_MUTATION = gql`
  mutation updateUserRole($role: Role!, $userId: ID!) {
    updateUserRole(role: $role, userId: $userId) {
      id
      name
      phone
      email
      lastLogin
      role
      accessAllowed
    }
  }
`;

export const VERIFY_OTP_MUTATION = gql`
  mutation verifyOtp($to: String!, $code: String!) {
    verifyOtp(to: $to, code: $code) {
      token
      user {
        id
        name
        phone
        email
        lastLogin
        role
        accessAllowed
        company {
          id
          dot
          dba
          name
          mailingAddress
          garagingAddress
          mcNumber
          mailingCity
          garagingCity
          accountOwner
          mailingZip
          garagingZip
          garagingCounty
          mailingCounty
          havePrevAuth
          yearsInBusiness
          mailingState
          garagingState
          phone
          eldProvider
          policyChangeData
          notes
        }
      }
    }
  }
`;

export const VERIFY_DISPATCHER_OTP_MUTATION = gql`
  mutation verifyDispatcherOTP($to: String!, $code: String!) {
    verifyDispatcherOTP(to: $to, code: $code) {
      token
      user {
        id
        name
        email
        phone
      }
    }
  }
`;

export const VERIFY_LT_OTP_MUTATION = gql`
  mutation verifyLtUserOtp($emailOtp: String!, $phoneOtp: String!) {
    verifyLtUserOtp(emailOtp: $emailOtp, phoneOtp: $phoneOtp) {
      token
      user {
        id
        name
        email
        phone
      }
    }
  }
`;

export const FETCH_ALL_COMPANIES_LIST_PAGINATED = gql`
  mutation fetchAllCompaniesPaginated($page: Int!, $org: Organizations!) {
    fetchAllCompaniesPaginated(page: $page, org: $org) {
      count
      companies {
        id
        dot
        dba
        name
        users {
          id
          name
          role
        }
      }
    }
  }
`;
export const SEARCH_COMPANIES_LIST_PAGINATED = gql`
  mutation fetchSearchCompaniesPaginated(
    $page: Int!
    $text: String!
    $org: Organizations!
  ) {
    fetchSearchCompaniesPaginated(page: $page, text: $text, org: $org) {
      count
      companies {
        id
        dot
        dba
        name
        users {
          id
          role
          name
        }
      }
    }
  }
`;

export const DISPATCHER_LOGIN_MUTATION = gql`
  mutation dispathcerLogin($email: String!, $password: String!) {
    dispathcerLogin(email: $email, password: $password) {
      token
      user {
        id
        name
        email
        phone
      }
    }
  }
`;

export const UPDATE_DISPATCHER_PHONE_MUTATION = gql`
  mutation updateDispatcherPhone($phone: String!, $email: String!) {
    updateDispatcherPhone(phone: $phone, email: $email) {
      id
      name
      phone
      email
    }
  }
`;
export const COMPANY_LOGIN_BY_LT_USER_MUTATION = gql`
  mutation companyLoginByLTUser($companyId: ID!) {
    companyLoginByLTUser(companyId: $companyId) {
      token
      user {
        id
        name
        phone
        email
        lastLogin
        role
        accessAllowed
        company {
          id
          dot
          name
          garagingAddress
          mailingAddress
          garagingState
          mcNumber
          dba
          garagingCity
          garagingCounty
          mailingCounty
          garagingZip
          mailingCity
          mailingZip
          mailingState
          havePrevAuth
          phone
          yearsInBusiness
          eldProvider
          notes
        }
      }
    }
  }
`;

export const SEND_USER_CREATION_EMAIL_MUTATION = gql`
  mutation sendEmail($email: String!, $body: String!, $subject: String!) {
    sendEmail(email: $email, body: $body, subject: $subject) {
      message
    }
  }
`;

export const SEND_COI_EMAIL_MUTATION = gql`
  mutation sendCoiEmails(
    $emails: [String]!
    $body: String!
    $subject: String!
  ) {
    sendCoiEmails(emails: $emails, body: $body, subject: $subject) {
      message
    }
  }
`;

export const COMPANY_INFO_BY_DOT_MUTATION = gql`
  mutation companyInfoByDot($dot: String!, $org: Organizations!) {
    companyInfoByDot(dot: $dot, org: $org) {
      id
      dot
      name
      garagingAddress
      mailingAddress
      garagingState
      mcNumber
      dba
      garagingCity
      garagingCounty
      garagingZip
      mailingCity
      mailingZip
      mailingState
      havePrevAuth
      phone
      yearsInBusiness
    }
  }
`;

export const COMPANY_NOTES_MUTATION = gql`
  mutation updateCompanyNotes($notes: String!) {
    updateCompanyNotes(notes: $notes) {
      id
      dot
      name
      garagingAddress
      mailingAddress
      garagingState
      mcNumber
      dba
      garagingCity
      garagingCounty
      garagingZip
      mailingCity
      mailingZip
      mailingState
      havePrevAuth
      phone
      yearsInBusiness
      eldProvider
      notes
    }
  }
`;

export const SEND_USER_SMS_MUTATION = gql`
  mutation sendSms($phone: String!, $body: String!) {
    sendSms(phone: $phone, body: $body) {
      message
    }
  }
`;

export const UPDATE_USER_ACCESS_MUTATION = gql`
  mutation updateUserAccess(
    $userId: ID!
    $role: Role!
    $accessAllowed: Boolean!
  ) {
    updateUserAccess(
      userId: $userId
      role: $role
      accessAllowed: $accessAllowed
    ) {
      message
    }
  }
`;

export const DELETE_VEHICLE_MUTATION = gql`
  mutation deleteVehicle($vehicleId: ID!) {
    deleteVehicle(vehicleId: $vehicleId) {
      id
      vehicleType
      subTrailerType
      subTruckType
      modelYear
      vehicleMake
      vehicleModel
      limit
      createdAt
      ownership
      bodyStyle
      value
      vehicleClass
      radius
      vehicleUse
      needCoverage
      vehicleZip
      antiTheft
      antiLockBrakes
      airbag
      status
      isDeleted
      vin
      endorsment {
        id
      }
      company {
        id
        name
      }
    }
  }
`;
export const RESTORE_VEHICLE_MUTATION = gql`
  mutation restoreVehicle($vehicleId: ID!) {
    restoreVehicle(vehicleId: $vehicleId) {
      id
      vehicleType
      subTrailerType
      subTruckType
      modelYear
      vehicleMake
      vehicleModel
      limit
      createdAt
      ownership
      bodyStyle
      value
      vehicleClass
      radius
      vehicleUse
      needCoverage
      vehicleZip
      antiTheft
      antiLockBrakes
      airbag
      status
      isDeleted
      vin
      endorsment {
        id
      }
      company {
        id
        name
      }
    }
  }
`;

export const DELETE_COI_MUTATION = gql`
  mutation deleteCoi($coiId: ID!) {
    deleteCoi(coiId: $coiId) {
      id
    }
  }
`;

export const CHECK_VEHICLE_WITH_VIN = gql`
  mutation checkVehicleWithVIN($vin: String!) {
    checkVehicleWithVIN(vin: $vin)
  }
`;

export const DELETE_DRIVER_MUTATION = gql`
  mutation deleteDriver($driverId: ID!) {
    deleteDriver(driverId: $driverId) {
      id
      firstName
      lastName
      dob
      dlNumber
      dlState
      dateOfHire
      phone
      dlYearsLicensed
      mvrStatus
      email
      isDeleted
      mvrCheckDate
      address
      city
      state
      zip
      isOwner
      eligibility
      endorsment {
        id
      }
    }
  }
`;

export const DELETE_COMMODITY_MUTATION = gql`
  mutation deleteCommodity($commodityId: ID!) {
    deleteCommodity(commodityId: $commodityId) {
      id
    }
  }
`;

export const DELETE_QUOTE_MUTATION = gql`
  mutation deleteQuote($quoteId: ID!) {
    deleteQuote(quoteId: $quoteId) {
      id
    }
  }
`;

export const DELETE_PACKAGE_MUTATION = gql`
  mutation deletePackage($packageId: ID!) {
    deletePackage(packageId: $packageId) {
      id
    }
  }
`;

export const MANUAL_CHECKER_REQUEST_MUTATION = gql`
  mutation fetchCheckrMutation($checkrData: CheckrInput) {
    fetchCheckrMutation(checkrData: $checkrData) {
      mvrStatus
      id
    }
  }
`;

export const SUBSCRIPTION_NOTIFICATION = gql`
  subscription MVRCheckrSubscription($companyId: ID!) {
    MVRCheckrSubscription(companyId: $companyId) {
      status
      severity
      lastUpdated
      payload
      createdAt
      type
    }
  }
`;

export const GET_COMPANY_NOTIFICATIONS_MUTATION = gql`
  mutation notificationsList($page: Float!) {
    notificationsList(page: $page) {
      notifications {
        id
        type
        status
        createdAt
        lastUpdated
        payload
        severity
        seenBy
      }
      count
    }
  }
`;

export const GET_USER_UNSEEN_NOTIFICATIONS_MUTATION = gql`
  mutation userUnseenNotificationsList($page: Float!) {
    userUnseenNotificationsList(page: $page) {
      notifications {
        id
        type
        status
        createdAt
        lastUpdated
        payload
        severity
        seenBy
      }
      count
    }
  }
`;

export const UPDATE_USER_BY_EMAIL_MUTATION = gql`
  mutation updateUserByEmail($email: String!, $phone: String!) {
    updateUserByEmail(email: $email, phone: $phone) {
      id
      phone
      email
    }
  }
`;

export const UPSERT_COI_MUTATION = gql`
  mutation upsertCoi($coiId: ID!, $policies: [ID], $input: CoiInput) {
    upsertCoi(coiId: $coiId, policies: $policies, input: $input) {
      holderName
      holderEmail
      certificateNumber
      expiration_date
    }
  }
`;

export const UPDATE_USER_MUTATION = gql`
  mutation updateUser(
    $id: ID!
    $email: String
    $phone: String
    $role: Role
    $accessAllowed: Boolean
    $name: String
  ) {
    updateUser(
      id: $id
      email: $email
      phone: $phone
      role: $role
      accessAllowed: $accessAllowed
      name: $name
    ) {
      id
      phone
      email
      role
      accessAllowed
      name
    }
  }
`;

export const DISPATCHER_COMPANIES_LIST_MUTATION = gql`
  mutation dispatcherCompanies($page: Int!) {
    dispatcherCompanies(page: $page) {
      count
      dispatcherOfCompanies {
        company {
          id
          name
          dot
          havePrevAuth
        }
        accessAllowed
        role
      }
    }
  }
`;

export const LOGIN_DISPATCHER_IN_COMPANY = gql`
  mutation loginDispatcherInCompany($companyId: String!) {
    loginDispatcherInCompany(companyId: $companyId) {
      token
      user {
        id
        name
        phone
        email
        lastLogin
        role
        accessAllowed
        company {
          id
          garagingState
          dot
          name
          mailingAddress
          mailingState
          mailingCity
          mailingZip
          yearsInBusiness
          havePrevAuth
          eldProvider
          notes
        }
      }
    }
  }
`;

export const SEARCH_COMPANIES_FOR_DISPATCHER_MUTATION = gql`
  mutation searchCompanyForDispatcher($text: String!) {
    searchCompanyForDispatcher(text: $text) {
      id
      name
      dot
      users {
        id
        name
        email
      }
      dispatchers {
        accessAllowed
        role
        dispatcher {
          id
          name
          email
        }
      }
    }
  }
`;

export const GET_COMPANY_FOLDERS_LIST_MUTATION = gql`
  mutation getCompanyDocumentsFoldersList {
    getCompanyDocumentsFoldersList
  }
`;

export const GET_COMPANY_FOLDER_FILES_LIST_MUTATION = gql`
  mutation getCompanyDocumentsFolderFilesList($folderName: String!) {
    getCompanyDocumentsFolderFilesList(folderName: $folderName) {
      name
      key
    }
  }
`;
export const CHECK_COMPANY_WITH_DOT_MUTATION = gql`
  mutation checkCompanyWithDOT($dot: String!) {
    checkCompanyWithDOT(dot: $dot)
  }
`;

export const SIGNUP_USER_WITH_COMPANY_MUTATION = gql`
  mutation singUpUserWithCompany(
    $dot: String!
    $email: String!
    $password: String!
    $role: Role!
    $phone: String!
    $accessAllowed: Boolean!
    $name: String!
  ) {
    singUpUserWithCompany(
      dot: $dot
      email: $email
      password: $password
      role: $role
      phone: $phone
      accessAllowed: $accessAllowed
      name: $name
    ) {
      message
    }
  }
`;
export const CREATE_USER_AND_CONNECT_WITH_COMPANY_MUTATION = gql`
  mutation createNewUserAndConnectWithCompany(
    $email: String!
    $role: Role!
    $phone: String!
    $accessAllowed: Boolean
    $name: String!
  ) {
    createNewUserAndConnectWithCompany(
      email: $email
      role: $role
      phone: $phone
      accessAllowed: $accessAllowed
      name: $name
    ) {
      id
      name
      email
      role
      phone
      accessAllowed
    }
  }
`;

export const UPDATE_USER_ROLE_AND_CONNECT_WITH_COMPANY = gql`
  mutation updateUserRoleAndConnectWithCompany(
    $userId: String
    $email: String
    $companyId: String!
    $role: Role!
    $accessAllowed: Boolean
  ) {
    updateUserRoleAndConnectWithCompany(
      userId: $userId
      companyId: $companyId
      email: $email
      role: $role
      accessAllowed: $accessAllowed
    ) {
      id
      name
      email
      phone
    }
  }
`;

export const RESTORE_DELETED_DERIVER_MUTATION = gql`
  mutation restoreDriver($driverId: ID!) {
    restoreDriver(driverId: $driverId) {
      id
      firstName
      lastName
      dob
      dlNumber
      dlState
      dateOfHire
      phone
      dlYearsLicensed
      mvrStatus
      email
      isDeleted
      mvrCheckDate
      address
      city
      state
      zip
      isOwner
      eligibility
      endorsment {
        id
      }
    }
  }
`;

export const FORGOT_PASSWORD_EMAIL_VERIFICATION = gql`
  mutation forgotPassword($email: String!) {
    forgotPassword(email: $email) {
      message
      resetToken
    }
  }
`;

export const FORGOT_LT_PASSWORD_EMAIL_VERIFICATION = gql`
  mutation forgotLtUserPassword($email: String!) {
    forgotLtUserPassword(email: $email) {
      message
      resetToken
    }
  }
`;

export const FORGOT_PASSWORD_RESET = gql`
  mutation resetPassword($resetToken: String!, $password: String!) {
    resetPassword(resetToken: $resetToken, password: $password) {
      token
      user {
        id
        name
        phone
        email
      }
    }
  }
`;

export const FORGOT_LT_PASSWORD_RESET = gql`
  mutation resetLtUserPass($password: String!) {
    resetLtUserPass(password: $password) {
      token
      user {
        id
        name
        phone
        email
      }
    }
  }
`;
export const FORGOT_DISPATCHER_PASSWORD_EMAIL_VERIFICATION = gql`
  mutation forgotDispatcherPassword($email: String!) {
    forgotDispatcherPassword(email: $email) {
      message
      resetToken
    }
  }
`;

export const FORGOT_DISPATCHER_PASSWORD_RESET = gql`
  mutation resetDispatcherPassword($resetToken: String!, $password: String!) {
    resetDispatcherPassword(resetToken: $resetToken, password: $password) {
      token
      user {
        id
        name
        email
        phone
      }
    }
  }
`;

export const CHANGE_PASSWORD = gql`
  mutation changePassword(
    $email: String!
    $password: String!
    $oldPassword: String!
  ) {
    changePassword(
      email: $email
      password: $password
      oldPassword: $oldPassword
    ) {
      message
    }
  }
`;
export const CHANGE_DISPATCHER_PASSWORD = gql`
  mutation changeDispatcherPassword(
    $email: String!
    $password: String!
    $oldPassword: String!
  ) {
    changeDispatcherPassword(
      email: $email
      password: $password
      oldPassword: $oldPassword
    ) {
      message
    }
  }
`;

export const CHANGE_LT_USER_PASSWORD = gql`
  mutation changeLtUserPassword(
    $email: String!
    $password: String!
    $oldPassword: String!
  ) {
    changeLtUserPassword(
      email: $email
      password: $password
      oldPassword: $oldPassword
    ) {
      message
    }
  }
`;

export const GOOGLE_LOGIN = gql`
  mutation userLoginGoogle($input: AuthInput!) {
    userLoginGoogle(input: $input) {
      token
      user {
        id
        name
        phone
        email
        lastLogin
        role
        accessAllowed
        company {
          id
          garagingState
          dot
          name
          mailingAddress
          mailingState
          mailingCity
          mailingZip
          mcNumber
          havePrevAuth
          eldProvider
          notes
        }
      }
    }
  }
`;

export const FLEXPORT_GOOGLE_LOGIN = gql`
  mutation flexportUserLoginGoogle($input: AuthInput!) {
    flexportUserLoginGoogle(input: $input) {
      token
      user {
        id
        name
        phone
        email
        lastLogin
        role
        accessAllowed
        company {
          id
          garagingState
          dot
          name
          mailingAddress
          mailingState
          mailingCity
          mailingZip
          mcNumber
          havePrevAuth
          eldProvider
          notes
        }
      }
    }
  }
`;

export const FACEBOOK_LOGIN = gql`
  mutation userLoginFacebook($input: AuthInput!) {
    userLoginFacebook(input: $input) {
      token
      user {
        id
        name
        phone
        email
        lastLogin
        role
        accessAllowed
        company {
          id
          garagingState
          dot
          name
          mailingAddress
          mailingState
          mailingCity
          mailingZip
          mcNumber
          havePrevAuth
          eldProvider
          notes
        }
      }
    }
  }
`;

export const FLEXPORT_FACEBOOK_LOGIN = gql`
  mutation flexportUserLoginFacebook($input: AuthInput!) {
    flexportUserLoginFacebook(input: $input) {
      token
      user {
        id
        name
        phone
        email
        lastLogin
        role
        accessAllowed
        company {
          id
          garagingState
          dot
          name
          mailingAddress
          mailingState
          mailingCity
          mailingZip
          mcNumber
          havePrevAuth
          eldProvider
          notes
        }
      }
    }
  }
`;

export const UPSERT_PACKAGE = gql`
  mutation upsertPackage($input: PackageInput!, $packageId: ID!) {
    upsertPackage(input: $input, packageId: $packageId) {
      id
      application {
        id
      }
      quote {
        id
      }
    }
  }
`;

export const FETCH_COMPLETE_COMPANY_INFO = gql`
  mutation fetchCompanyCompleteInfoForDispatcher($companyId: ID!) {
    fetchCompanyCompleteInfoForDispatcher(companyId: $companyId) {
      id
      garagingState
      garagingCity
      garagingZip
      garagingAddress
      mcNumber
      dot
      name
      mailingAddress
      mailingState
      mailingCity
      mailingZip
      yearsInBusiness
      havePrevAuth
      accountOwner
      eldProvider
      accountStage
      notes
      trucks {
        id
        vin
        vehicleType
        subTrailerType
        subTruckType
        modelYear
        vehicleMake
        vehicleModel
        gvw
        rearAxles
        garagingCity
        operationRadius
        annualMileage
        safetyFeatures
        limit
        createdAt
        ownership
        bodyStyle
        value
        valuePermEquipment
        rearAxles
        vehicleClass
        radius
        vehicleUse
        needCoverage
        vehicleZip
        loanOrLease
        antiTheft
        antiLockBrakes
        airbag
        isDeleted
        valuePermEquipment
        typeOfTrailerHitch
        grossVehicleWeight
        vehicleTonnage
        numberOfDrivingWheels
        percentageOfRepossessionWork
        numberOfRearAxles
        numberOfPassengerSeats
        vehicleEquippedWheelchair
        stretchLength
        typeOfSUV
        vehicleTankSize
        haulGoodsOnForHireBasis
        glassVinEtching
        transportPassengersRevenue
        perimeterSeatingOrPartyBus
        numberOfStalls
        publicTransportationIndicator
        permanentFoundation
        lengthOfTrailer
        collision
        comprehensive
        cwDeductibles
        inPossessionForMoreThan30Days
        isDeleted
        status
      }
      drivers {
        id
        firstName
        lastName
        dob
        dlNumber
        dlState
        dateOfHire
        phone
        dlYearsLicensed
        mvrStatus
        email
        isDeleted
        mvrCheckDate
        address
        city
        state
        zip
        isOwner
        eligibility
        yearsExperience
        isSr22
        hasAnyViolationsInPast5Years
        licenseIssueDate
        past3YearMajorViolations
        past3YearsAccidents
        past3YearsViolations
        past3YearsSuspensions
        accidentsViolationsDetails
        totalMajorConvictionsDates
        totalMajorConvictions
        maritalStatus
        status
      }
      commodities {
        id
        type
        subType
        minValue
        maxValue
        percentage
        status
        isDeleted
      }
      policies {
        id
        status
        policyNumber
        lob
        issuingCarrier
        effectiveDate
        expirationDate
        endorsmentsPremium
        totalPremium
        quote {
          limit
          deductible
          effectiveDate
          expirationDate
        }
        # endorsments {
        #   bindingPremium
        # }
        totalPremium
      }
    }
  }
`;
export const FETCH_SINGLE_POLICY_MUTATION = gql`
  mutation fetchSinglePolicy($policyId: ID) {
    fetchSinglePolicy(policyId: $policyId) {
      id
      status
      policyNumber
      totalPremium
      endorsmentsPremium
      issuingCarrier
      billingCarrier
      effectiveDate
      expirationDate
      accountManager
      lob
      endorsments {
        id
        name
        status
        Operations {
          id
          action
          subject
          subjectKey
        }
      }
      package {
        id
        totalLimit
        totalDeductible
        totalPremium
        totalAmountPaid
        pfa
      }
      quote {
        id
        status
        lob
        bindingPremium
        limit
        ascendQuoteId
        deductible
        quoteNumber
        invoicedBy
        admitted
        issuingCarrier
        billingCarrier
        effectiveDate
        expirationDate
        application {
          id
          stage
          activeAuth
          averageViolations
          doTheseApply
          doTheseApplyCw
          preferedCarrier
          priorityStatus
          operationsDescription
          driversAbove23
          cdlExperience
          anySeriousViolations
          currentProgCust
          businessStructure
          driverSummary
          totalValueTrucks
          totalValueTrailers
          grossAnnualTruckRev
          annualTotalMileage
          haulsUnder50Miles
          haulsUnder200Miles
          haulsUnder500Miles
          haulsAbove500Miles
          currentlyInsured
          otherCoverages
          totalAdditionalInsureds
          totalNamedInsureds
          blanketAdditionalNeeded
          blanketWaiverSubrogations
          stateOrFederalFilingsReq
          snapshotPreview
          effectiveDate
          towingLaborStorageLimit
          trailerInterchangeLimit
          autoLiabilityLossCount
          autoLiabilityLossPaid
          physicalDamageLossCount
          physicalDamageLossPaid
          cargoLossCount
          cargoLossPaid
          truckerGenLiabLossCount
          truckerGenLiabLossPaid
          lossesConfirmedVsPending
          createdAt
          updatedAt
          isTest
          isThereRigging
          numberLosses3Years
          currentPage
          numberDrivers
          numberPowerUnits
          lobs
          avgRadOps
          domiciledWithin50Miles
          limitInsPerPowerUnit
          limitInsPerPowerUnitNonOwned
          perOccurenceLimit
          deductible
          spoilageFreezingCoverage
          mtcAdditionalCoverage
          pollutionCleanUp
          ownersGoodExtension
          nonOwnedTrailer
          expirationDate
          disclaimer
          status
          typeOfOps
          typeOfOpsDescriptionOther
          glOrBop
          yearCurrentBusiness
          limitAl
          limitPd
          limitMtc
          limitGl
          limitNtl
          trailerInterchangeDeductible
          deductibleAl
          deductiblePd
          deductibleMtc
          deductibleGl
          deductibleNtl
          numberLossesPast3YearsGA
        }
      }
    }
  }
`;

export const FETCH_COMPANY_POLICIES = gql`
  mutation fetchCompanyPolicies {
    fetchCompanyPolicies {
      id
      status
      policyNumber
      totalPremium
      endorsmentsPremium
      issuingCarrier
      billingCarrier
      effectiveDate
      expirationDate
      accountManager
      lob
      endorsments {
        id
        name
        status
        Operations {
          id
          action
          subject
          subjectKey
        }
      }
      package {
        id
        totalLimit
        totalDeductible
        totalPremium
        totalAmountPaid
        pfa
      }
      quote {
        id
        status
        lob
        bindingPremium
        limit
        ascendQuoteId
        deductible
        quoteNumber
        invoicedBy
        admitted
        issuingCarrier
        billingCarrier
        effectiveDate
        expirationDate
        application {
          id
          stage
          activeAuth
          averageViolations
          doTheseApply
          doTheseApplyCw
          preferedCarrier
          priorityStatus
          operationsDescription
          driversAbove23
          cdlExperience
          anySeriousViolations
          currentProgCust
          businessStructure
          driverSummary
          totalValueTrucks
          totalValueTrailers
          grossAnnualTruckRev
          annualTotalMileage
          haulsUnder50Miles
          haulsUnder200Miles
          haulsUnder500Miles
          haulsAbove500Miles
          currentlyInsured
          otherCoverages
          totalAdditionalInsureds
          totalNamedInsureds
          blanketAdditionalNeeded
          blanketWaiverSubrogations
          stateOrFederalFilingsReq
          snapshotPreview
          effectiveDate
          towingLaborStorageLimit
          trailerInterchangeLimit
          autoLiabilityLossCount
          autoLiabilityLossPaid
          physicalDamageLossCount
          physicalDamageLossPaid
          cargoLossCount
          cargoLossPaid
          truckerGenLiabLossCount
          truckerGenLiabLossPaid
          lossesConfirmedVsPending
          createdAt
          updatedAt
          isTest
          isThereRigging
          numberLosses3Years
          currentPage
          numberDrivers
          numberPowerUnits
          lobs
          avgRadOps
          domiciledWithin50Miles
          limitInsPerPowerUnit
          limitInsPerPowerUnitNonOwned
          perOccurenceLimit
          deductible
          spoilageFreezingCoverage
          mtcAdditionalCoverage
          pollutionCleanUp
          ownersGoodExtension
          nonOwnedTrailer
          expirationDate
          disclaimer
          status
          typeOfOps
          typeOfOpsDescriptionOther
          glOrBop
          yearCurrentBusiness
          limitAl
          limitPd
          limitMtc
          limitGl
          limitNtl
          trailerInterchangeDeductible
          deductibleAl
          deductiblePd
          deductibleMtc
          deductibleGl
          deductibleNtl
          numberLossesPast3YearsGA
        }
      }
    }
  }
`;
export const GET_COMPANY_REFFERAL_STRING = gql`
  mutation getCompanyRefferalLink {
    getCompanyRefferalLink
  }
`;

export const GET_COMPANY_INFO = gql`
  mutation fetchCompanyInfo {
    fetchCompanyInfo {
      id
      garagingState
      dot
      name
      mailingAddress
      mailingState
      mailingCity
      mailingZip
      mailingCounty
      garagingAddress
      garagingState
      garagingCity
      garagingZip
      garagingCounty
      yearsInBusiness
      havePrevAuth
      accountOwner
      eldProvider
      accountStage
      commoditiesSnapShot
      vehiclesSnapShot
      driversSnapShot
      notes
      trucks {
        id
        vin
        vehicleType
        subTrailerType
        subTruckType
        modelYear
        vehicleMake
        vehicleModel
        gvw
        rearAxles
        garagingCity
        operationRadius
        annualMileage
        safetyFeatures
        limit
        createdAt
        ownership
        bodyStyle
        value
        valuePermEquipment
        rearAxles
        vehicleClass
        radius
        vehicleUse
        needCoverage
        vehicleZip
        loanOrLease
        antiTheft
        antiLockBrakes
        airbag
        isDeleted
        valuePermEquipment
        typeOfTrailerHitch
        grossVehicleWeight
        vehicleTonnage
        numberOfDrivingWheels
        percentageOfRepossessionWork
        numberOfRearAxles
        numberOfPassengerSeats
        vehicleEquippedWheelchair
        stretchLength
        typeOfSUV
        vehicleTankSize
        haulGoodsOnForHireBasis
        glassVinEtching
        transportPassengersRevenue
        perimeterSeatingOrPartyBus
        numberOfStalls
        publicTransportationIndicator
        permanentFoundation
        lengthOfTrailer
        collision
        comprehensive
        cwDeductibles
        inPossessionForMoreThan30Days
        isDeleted
        status
      }
      drivers {
        id
        firstName
        lastName
        dob
        dlNumber
        dlState
        dateOfHire
        phone
        dlYearsLicensed
        mvrStatus
        email
        isDeleted
        mvrCheckDate
        address
        city
        state
        zip
        isOwner
        eligibility
        yearsExperience
        isSr22
        hasAnyViolationsInPast5Years
        licenseIssueDate
        past3YearMajorViolations
        past3YearsAccidents
        past3YearsViolations
        past3YearsSuspensions
        totalMajorConvictions
        accidentsViolationsDetails
        totalMajorConvictionsDates
        maritalStatus
        status
      }
      commodities {
        id
        type
        subType
        minValue
        maxValue
        percentage
        status
        isDeleted
      }
    }
  }
`;

export const BROKER_USER_COMPANY_CONNENCTION = gql`
  mutation brokerUserCompanyConnection(
    $email: String!
    $name: String!
    $phone: String!
    $companyId: ID!
  ) {
    brokerUserCompanyConnection(
      email: $email
      name: $name
      phone: $phone
      companyId: $companyId
    ) {
      token
      user {
        id
        name
        phone
        email
        lastLogin
        role
        accessAllowed
        company {
          id
          garagingState
          dot
          name
          mailingAddress
          mailingState
          mailingCity
          mailingZip
          yearsInBusiness
          havePrevAuth
          eldProvider
          notes
        }
      }
    }
  }
`;

export const FETCH_SINGLE_POLICY_QUERY = gql`
  mutation policyInfo($policyId: String!) {
    policyInfo(policyId: $policyId) {
      status
      policyNumber
      totalPremium
      endorsmentsPremium
      issuingCarrier
      billingCarrier
      effectiveDate
      expirationDate
      accountManager
      paymentSource
      paymentType
      downPaymentAmount
      downPaymentPercentage
      taxes
      platformFee
      PlatformFeeType
      lob
      package {
        totalLimit
        totalDeductible
      }
      quote {
        id
        status
        lob
        bindingPremium
        limit
        ascendQuoteId
        deductible
        quoteNumber
        invoicedBy
        admitted
        issuingCarrier
        billingCarrier
        effectiveDate
        expirationDate
        application {
          id
          stage
          preferedCarrier
          effectiveDate
          createdAt
          lobs
          domiciledWithin50Miles
          limitInsPerPowerUnit
          limitInsPerPowerUnitNonOwned
          perOccurenceLimit
          deductible
          expirationDate
          status
          limitAl
          limitPd
          limitMtc
          limitGl
          limitNtl
          trailerInterchangeDeductible
          deductibleAl
          deductiblePd
          deductibleMtc
          deductibleGl
          deductibleNtl
        }
      }
    }
  }
`;

export const SEND_MESSAGE_TO_BROKER_MUTATION = gql`
  mutation userMessageToBroker($message: String) {
    userMessageToBroker(message: $message) {
      message
    }
  }
`;

export const SEND_MESSAGE_TO_SLACK_BY_CHANNEL_NAME_MUTATION = gql`
  mutation slackMessageByChannelName($message: String!, $channelName: String!) {
    slackMessageByChannelName(message: $message, channelName: $channelName) {
      message
    }
  }
`;

export const CREATE_ELD_PROVIDER_MUTATION = gql`
  mutation createNewEldProviderWithCompany(
    $companyId: ID!
    $token: String!
    $provider: String!
  ) {
    createNewEldProviderWithCompany(
      companyId: $companyId
      token: $token
      provider: $provider
    ) {
      id
      dot
      dba
      name
      mailingAddress
      garagingAddress
      garagingCounty
      mailingCounty
      mcNumber
      mailingCity
      garagingCity
      mailingZip
      garagingZip
      yearsInBusiness
      mailingState
      garagingState
      phone
      havePrevAuth
      policyChangeData
      eldProvider
      notes
      trucks {
        id
        vin
        vehicleType
        subTrailerType
        subTruckType
        modelYear
        vehicleMake
        vehicleModel
        gvw
        rearAxles
        garagingCity
        operationRadius
        annualMileage
        safetyFeatures
        limit
        createdAt
        ownership
        bodyStyle
        value
        valuePermEquipment
        rearAxles
        vehicleClass
        radius
        vehicleUse
        needCoverage
        vehicleZip
        loanOrLease
        antiTheft
        antiLockBrakes
        airbag
        isDeleted
        valuePermEquipment
        typeOfTrailerHitch
        grossVehicleWeight
        vehicleTonnage
        numberOfDrivingWheels
        percentageOfRepossessionWork
        numberOfRearAxles
        numberOfPassengerSeats
        vehicleEquippedWheelchair
        stretchLength
        typeOfSUV
        vehicleTankSize
        haulGoodsOnForHireBasis
        glassVinEtching
        transportPassengersRevenue
        perimeterSeatingOrPartyBus
        numberOfStalls
        publicTransportationIndicator
        permanentFoundation
        lengthOfTrailer
        collision
        comprehensive
        cwDeductibles
        inPossessionForMoreThan30Days
        isDeleted
        status
      }
      drivers {
        id
        firstName
        lastName
        dob
        dlNumber
        dlState
        dateOfHire
        phone
        dlYearsLicensed
        mvrStatus
        email
        isDeleted
        mvrCheckDate
        address
        city
        state
        zip
        isOwner
        eligibility
        yearsExperience
        isSr22
        hasAnyViolationsInPast5Years
        licenseIssueDate
        past3YearMajorViolations
        past3YearsAccidents
        past3YearsViolations
        past3YearsSuspensions
        accidentsViolationsDetails
        totalMajorConvictionsDates
        totalMajorConvictions
        maritalStatus
        status
      }
      EldProvider {
        id
        providerName
        isConnected
      }
    }
  }
`;

export const DELETE_ELD_PROVIDER_MUTATION = gql`
  mutation deleteEldById($id: ID!) {
    deleteEldById(id: $id) {
      id
    }
  }
`;

export const SYNC_ELD_PROVIDER_MUTATION = gql`
  mutation syncEldById($id: ID!, $companyId: ID!) {
    syncEldById(id: $id, companyId: $companyId) {
      id
      dot
      dba
      name
      mailingAddress
      garagingAddress
      garagingCounty
      mailingCounty
      mcNumber
      mailingCity
      garagingCity
      mailingZip
      garagingZip
      yearsInBusiness
      mailingState
      garagingState
      phone
      havePrevAuth
      policyChangeData
      eldProvider
      notes
      trucks {
        id
        vin
        vehicleType
        subTrailerType
        subTruckType
        modelYear
        vehicleMake
        vehicleModel
        gvw
        rearAxles
        garagingCity
        operationRadius
        annualMileage
        safetyFeatures
        limit
        createdAt
        ownership
        bodyStyle
        value
        valuePermEquipment
        rearAxles
        vehicleClass
        radius
        vehicleUse
        needCoverage
        vehicleZip
        loanOrLease
        antiTheft
        antiLockBrakes
        airbag
        isDeleted
        valuePermEquipment
        typeOfTrailerHitch
        grossVehicleWeight
        vehicleTonnage
        numberOfDrivingWheels
        percentageOfRepossessionWork
        numberOfRearAxles
        numberOfPassengerSeats
        vehicleEquippedWheelchair
        stretchLength
        typeOfSUV
        vehicleTankSize
        haulGoodsOnForHireBasis
        glassVinEtching
        transportPassengersRevenue
        perimeterSeatingOrPartyBus
        numberOfStalls
        publicTransportationIndicator
        permanentFoundation
        lengthOfTrailer
        isDeleted
        status
      }
      drivers {
        id
        firstName
        lastName
        dob
        dlNumber
        dlState
        dateOfHire
        phone
        dlYearsLicensed
        mvrStatus
        email
        isDeleted
        mvrCheckDate
        address
        city
        state
        zip
        isOwner
        eligibility
        yearsExperience
        isSr22
        hasAnyViolationsInPast5Years
        licenseIssueDate
        past3YearMajorViolations
        past3YearsAccidents
        past3YearsViolations
        past3YearsSuspensions
        accidentsViolationsDetails
        totalMajorConvictionsDates
        totalMajorConvictions
        maritalStatus
        status
      }
      EldProvider {
        id
        providerName
        isConnected
      }
    }
  }
`;

export const ERROR_MESSAGE_TO_SLACK = gql`
  mutation errorMessageToSlack(
    $normalUser: Boolean!
    $message: String!
    $ltUserName: String
  ) {
    errorMessageToSlack(
      normalUser: $normalUser
      message: $message
      ltUserName: $ltUserName
    )
  }
`;

export const QUOTE_PERCENTAGE_QUERY = gql`
  mutation upsertQuotePercentage($packageId: ID!, $percentage: Float!) {
    upsertQuotePercentage(packageId: $packageId, percentage: $percentage) {
      effectiveDate
      expirationDate
      totalLimit
      totalPremium
      totalDeductible
      monthlyPayment
      downPaymentPercentage
      lob
      pfa
      id
      carrier
      ascendProgramId
      ascendProgramUrl
      ascendInvoiceId
      ascendInvoiceUrl
      ascendInvoiceNumber
      ascendInvoiceDueDate
      File {
        id
        name
        type
        url
      }
      quote {
        id
        status
        lob
        bindingPremium
        limit
        deductible
        quoteNumber
        invoicedBy
        admitted
        ascendQuoteId
        issuingCarrier
        billingCarrier
        quoteFileLocation
        quoteSigned
        quoteSignedFileLocation
        purchasedFlag
        oppStage
        downPaymentPercentage
        downPaymentAmount
        paymentType
        paymentSource
        applicationError
        declinedReason
        taxes
        platformFee
        PlatformFeeType
        File {
          id
          name
          type
          url
        }
        policy {
          id
          policyNumber
        }
      }
    }
  }
`;

export const CLOSE_ENDORSEMENT_MUTATION = gql`
  mutation closeEndorsement(
    $endorsementId: ID!
    $endorsement: EndorsmentInput!
  ) {
    closeEndorsement(endorsementId: $endorsementId, endorsement: $endorsement) {
      id
      companyId
      name
      type
      amount
      dateComplete
      status
      sfId
      note
    }
  }
`;

export const UPDATE_LIMIT_AND_DEDUCTIBLE_MUTATION = gql`
  mutation updateLimitsAndDeductiblesPolicy(
    $policyId: ID!
    $policy: PolicyInput!
  ) {
    updateLimitsAndDeductiblesPolicy(policyId: $policyId, policy: $policy) {
      id
      limitsDeductiblesChange
    }
  }
`;

export const UPDATE_COMPANY_COMODITIES_MUTATION = gql`
  mutation updateCompanyCommodities($commodities: [CommoditiesInput]) {
    updateCompanyCommodities(commodities: $commodities) {
      commoditiesSnapShot
    }
  }
`;

export const UPDATE_COMPANY_VEHICLES_MUTATION = gql`
  mutation updateCompanyDriverOrVehicleSnapshot(
    $snapShotData: JSON
    $type: String!
  ) {
    updateCompanyDriverOrVehicleSnapshot(
      snapShotData: $snapShotData
      type: $type
    ) {
      vehiclesSnapShot
      driversSnapShot
    }
  }
`;

export const UPDATE_COMPANY_SNAPSHOT_MUTATION = gql`
  mutation updateCommoditiesSnapshot {
    updateCommoditiesSnapshot {
      commoditiesSnapShot
    }
  }
`;

export const UPDATE_VEHICLE_SNAPSHOT_MUTATION = gql`
  mutation resetCompanyDriverOrVehicleSnapshot($type: String!) {
    resetCompanyDriverOrVehicleSnapshot(type: $type) {
      vehiclesSnapShot
      driversSnapShot
    }
  }
`;

export const GET_POWER_SESSION_MUTATION = gql`
  mutation getPowerSessionURL($userId: String) {
    getPowerSessionURL(userId: $userId)
  }
`;

export const CHECK_ACCOUNT_EXISTING_POLICIES_MUTATION = gql`
  mutation checkAccountActivePolicies($lobs: String!, $operation: String!) {
    checkAccountActivePolicies(lobs: $lobs, operation: $operation)
  }
`;
